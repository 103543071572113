<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.89706 3.30232L13.9907 7.75926L8.89706 12.2162V10.2004C8.89706 9.65068 8.44639 9.19012 7.87909 9.20878C5.896 9.27398 4.50352 9.71888 3.40983 10.4266C2.79328 10.8255 2.28442 11.3005 1.82673 11.8116C2.03135 9.92672 2.63246 8.65396 3.47582 7.80842C4.52935 6.75218 6.04582 6.27703 7.91463 6.20903C8.44662 6.18967 8.89706 5.75833 8.89706 5.20044V3.30232ZM9.14094 2.18695C8.656 1.76263 7.89706 2.10701 7.89706 2.75138V5.20034C7.89677 5.2008 7.89619 5.20163 7.89514 5.20269C7.89132 5.20654 7.88559 5.20942 7.87827 5.20969C5.87665 5.28252 4.0699 5.79678 2.76781 7.10222C1.46324 8.41015 0.75 10.4268 0.75 13.3181C0.75 13.5327 0.886978 13.7234 1.09038 13.7919C1.29377 13.8604 1.51821 13.7915 1.64808 13.6206C2.37078 12.6697 3.03088 11.8628 3.95308 11.2661C4.86185 10.6781 6.06445 10.271 7.89706 10.2087V12.7671C7.89706 13.4115 8.656 13.7559 9.14094 13.3316L14.6492 8.51184C15.1045 8.11343 15.1045 7.4051 14.6492 7.00669L9.14094 2.18695Z"
      fill="currentColor"
    />
  </svg>
</template>
