<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.023 2.5L8.43184 3.68632C8.25916 3.81507 8.04952 3.88462 7.83412 3.88462H2V12.5C2 13.0523 2.44772 13.5 3 13.5H13C13.5523 13.5 14 13.0523 14 12.5V2.5H10.023ZM9.49179 1.64872C9.6213 1.55216 9.77853 1.5 9.94008 1.5H14.35C14.709 1.5 15 1.79101 15 2.15V12.5C15 13.6046 14.1046 14.5 13 14.5H3C1.89543 14.5 1 13.6046 1 12.5V3.53462C1 3.17563 1.29102 2.88462 1.65 2.88462H7.83412L9.49179 1.64872ZM8.00098 5.82349C8.27712 5.82349 8.50098 6.04734 8.50098 6.32349V8.32471H10.5009C10.7771 8.32471 11.0009 8.54856 11.0009 8.82471C11.0009 9.10085 10.7771 9.32471 10.5009 9.32471H8.50098V11.3259C8.50098 11.602 8.27712 11.8259 8.00098 11.8259C7.72483 11.8259 7.50098 11.602 7.50098 11.3259V9.32471H5.49854C5.22239 9.32471 4.99854 9.10085 4.99854 8.82471C4.99854 8.54856 5.22239 8.32471 5.49854 8.32471H7.50098V6.32349C7.50098 6.04734 7.72483 5.82349 8.00098 5.82349Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "NewFolder",
}
</script>
