<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5801 7.52612C12.5801 10.2881 10.3411 12.5271 7.5791 12.5271C4.81714 12.5271 2.57812 10.2881 2.57812 7.52612C2.57812 4.76416 4.81714 2.52515 7.5791 2.52515C10.3411 2.52515 12.5801 4.76416 12.5801 7.52612ZM11.3964 12.1567C10.359 13.0128 9.02913 13.5271 7.5791 13.5271C4.26485 13.5271 1.57812 10.8404 1.57812 7.52612C1.57812 4.21187 4.26485 1.52515 7.5791 1.52515C10.8933 1.52515 13.5801 4.21187 13.5801 7.52612C13.5801 9.0303 13.0266 10.4052 12.1123 11.4584L13.1744 12.5205L13.8035 13.1496L14.118 13.4641L14.2753 13.6214C14.4706 13.8166 14.4706 14.1332 14.2753 14.3285C14.0801 14.5238 13.7635 14.5238 13.5682 14.3285L13.4109 14.1712L13.0964 13.8567L12.4673 13.2276L11.3964 12.1567Z"
      fill="currentColor"
    />
  </svg>
</template>
