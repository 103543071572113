<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.023 2.5L8.43184 3.68632C8.25916 3.81507 8.04952 3.88462 7.83412 3.88462H2V12.5C2 13.0523 2.44772 13.5 3 13.5H13C13.5523 13.5 14 13.0523 14 12.5V2.5H10.023ZM9.49179 1.64872C9.6213 1.55216 9.77853 1.5 9.94008 1.5H14.35C14.709 1.5 15 1.79101 15 2.15V12.5C15 13.6046 14.1046 14.5 13 14.5H3C1.89543 14.5 1 13.6046 1 12.5V3.53462C1 3.17563 1.29102 2.88462 1.65 2.88462H7.83412L9.49179 1.64872ZM8.00059 5.66821C8.13319 5.66824 8.26036 5.72094 8.35411 5.81473L10.8531 8.31473C11.0484 8.51003 11.0483 8.82661 10.853 9.02184C10.6577 9.21706 10.3411 9.217 10.1459 9.0217L8.5 7.37516V11.6682C8.5 11.9444 8.27614 12.1682 8 12.1682C7.72386 12.1682 7.5 11.9444 7.5 11.6682V7.37547L5.853 9.02184C5.6577 9.21706 5.34111 9.217 5.14589 9.0217C4.95067 8.8264 4.95073 8.50981 5.14603 8.31459L7.647 5.81459C7.74079 5.72084 7.86798 5.66819 8.00059 5.66821Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "FolderUpload",
}
</script>
