<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.97534 11.5115C8.97534 11.2354 9.1992 11.0115 9.47534 11.0115H11.4831C13.1474 11.0115 14.4967 9.66229 14.4967 7.99794C14.4967 6.3336 13.1474 4.98438 11.4831 4.98437L9.47534 4.98438C9.1992 4.98438 8.97534 4.76052 8.97534 4.48438C8.97534 4.20823 9.1992 3.98438 9.47534 3.98438L11.4831 3.98438C13.6997 3.98438 15.4967 5.78131 15.4967 7.99794C15.4967 10.2146 13.6997 12.0115 11.4831 12.0115H9.47534C9.1992 12.0115 8.97534 11.7876 8.97534 11.5115ZM7.02498 4.48468C7.02498 4.76083 6.80112 4.98468 6.52498 4.98468H4.51723C2.85288 4.98468 1.50366 6.3339 1.50366 7.99825C1.50366 9.6626 2.85288 11.0118 4.51723 11.0118H6.52498C6.80112 11.0118 7.02498 11.2357 7.02498 11.5118C7.02498 11.788 6.80112 12.0118 6.52498 12.0118H4.51723C2.3006 12.0118 0.503662 10.2149 0.503662 7.99825C0.503662 5.78162 2.3006 3.98468 4.51723 3.98468H6.52498C6.80112 3.98468 7.02498 4.20854 7.02498 4.48468ZM10.008 8.49808C10.2841 8.49808 10.508 8.27422 10.508 7.99808C10.508 7.72194 10.2841 7.49808 10.008 7.49808H5.99249C5.71635 7.49808 5.49249 7.72194 5.49249 7.99808C5.49249 8.27422 5.71635 8.49808 5.99249 8.49808H10.008Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Link1",
}
</script>
