<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.67611 2.46483H5.77031V2.23535C5.77031 1.40693 6.44188 0.735352 7.27031 0.735352H8.72927C9.5577 0.735352 10.2293 1.40692 10.2293 2.23535V2.46483H12.3235H12.8235V2.47314H14C14.2761 2.47314 14.5 2.697 14.5 2.97314C14.5 3.24929 14.2761 3.47314 14 3.47314H12.8235V12.7648C12.8235 14.1455 11.7042 15.2648 10.3235 15.2648H5.67611C4.29539 15.2648 3.17611 14.1455 3.17611 12.7648V3.47314H2C1.72386 3.47314 1.5 3.24929 1.5 2.97314C1.5 2.697 1.72386 2.47314 2 2.47314H3.17611V2.46483H3.67611ZM4.17611 3.47314V12.7648C4.17611 13.5933 4.84768 14.2648 5.67611 14.2648H10.3235C11.1519 14.2648 11.8235 13.5933 11.8235 12.7648V3.47314H4.17611ZM9.22927 2.46483H6.77031V2.23535C6.77031 1.95921 6.99417 1.73535 7.27031 1.73535H8.72927C9.00541 1.73535 9.22927 1.95921 9.22927 2.23535V2.46483ZM6.48633 5.81366C6.76247 5.81366 6.98633 6.03751 6.98633 6.31366V11.3137C6.98633 11.5898 6.76247 11.8137 6.48633 11.8137C6.21019 11.8137 5.98633 11.5898 5.98633 11.3137V6.31366C5.98633 6.03751 6.21019 5.81366 6.48633 5.81366ZM10.0137 6.31366C10.0137 6.03751 9.78981 5.81366 9.51367 5.81366C9.23753 5.81366 9.01367 6.03751 9.01367 6.31366V11.3137C9.01367 11.5898 9.23753 11.8137 9.51367 11.8137C9.78981 11.8137 10.0137 11.5898 10.0137 11.3137V6.31366Z"
      fill="currentColor"
    />
  </svg>
</template>
