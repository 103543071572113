<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9971 14H3.99707C3.16864 14 2.49707 13.3284 2.49707 12.5V3.5C2.49707 2.67157 3.16864 2 3.99707 2H9.5V3.99989C9.5 5.38061 10.6193 6.4999 12 6.49989L13.4971 6.49988V12.5C13.4971 13.3284 12.8255 14 11.9971 14ZM14.4502 5.49987C14.3612 5.04668 14.1476 4.62366 13.8291 4.2806L11.5246 1.79887C11.0516 1.28945 10.3878 1 9.69261 1H3.99707C2.61636 1 1.49707 2.11929 1.49707 3.5V12.5C1.49707 13.8807 2.61636 15 3.99707 15H11.9971C13.3778 15 14.4971 13.8807 14.4971 12.5V5.98173L14.4968 5.94777L14.4968 5.49987L14.4502 5.49987ZM13.4176 5.49988L12 5.49989C11.1716 5.4999 10.5 4.82832 10.5 3.99989V2.23582C10.6063 2.30368 10.7044 2.38525 10.7918 2.47932L13.0963 4.96105C13.2414 5.11735 13.3502 5.30132 13.4176 5.49988ZM8.00107 6C8.13368 6.00003 8.26085 6.05273 8.3546 6.14652L10.8536 8.64652C11.0488 8.84182 11.0488 9.1584 10.8535 9.35362C10.6582 9.54885 10.3416 9.54879 10.1464 9.35348L8.50049 7.70695V12C8.50049 12.2761 8.27663 12.5 8.00049 12.5C7.72435 12.5 7.50049 12.2761 7.50049 12V7.70726L5.85348 9.35362C5.65818 9.54885 5.3416 9.54879 5.14638 9.35348C4.95115 9.15818 4.95122 8.8416 5.14652 8.64638L7.64749 6.14638C7.74128 6.05263 7.86846 5.99997 8.00107 6Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "FileUpload",
}
</script>
