<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9971 14H3.99707C3.16864 14 2.49707 13.3284 2.49707 12.5V3.5C2.49707 2.67157 3.16864 2 3.99707 2H9.5V3.99989C9.5 5.38061 10.6193 6.4999 12 6.49989L13.4971 6.49988V12.5C13.4971 13.3284 12.8255 14 11.9971 14ZM14.4502 5.49987C14.3612 5.04668 14.1476 4.62366 13.8291 4.2806L11.5246 1.79887C11.0516 1.28945 10.3878 1 9.69261 1H3.99707C2.61636 1 1.49707 2.11929 1.49707 3.5V12.5C1.49707 13.8807 2.61636 15 3.99707 15H11.9971C13.3778 15 14.4971 13.8807 14.4971 12.5V5.98173L14.4968 5.94777L14.4968 5.49987L14.4502 5.49987ZM13.4176 5.49988L12 5.49989C11.1716 5.4999 10.5 4.82832 10.5 3.99989V2.23582C10.6063 2.30368 10.7044 2.38525 10.7918 2.47932L13.0963 4.96105C13.2414 5.11735 13.3502 5.30132 13.4176 5.49988ZM8 5.5C8.27614 5.5 8.5 5.72386 8.5 6V8H10.5024C10.7785 8 11.0024 8.22386 11.0024 8.5C11.0024 8.77614 10.7785 9 10.5024 9H8.5V11.0024C8.5 11.2785 8.27614 11.5024 8 11.5024C7.72386 11.5024 7.5 11.2785 7.5 11.0024V9H5.5C5.22386 9 5 8.77614 5 8.5C5 8.22386 5.22386 8 5.5 8H7.5V6C7.5 5.72386 7.72386 5.5 8 5.5Z"
      fill="currentColor"
    />
  </svg>
</template>
