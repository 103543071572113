<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 4V5H6V4C6 2.89543 6.89543 2 8 2C9.10457 2 10 2.89543 10 4ZM5 5V4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4V5H12C13.3807 5 14.5 6.11929 14.5 7.5V12.5C14.5 13.8807 13.3807 15 12 15H4C2.61929 15 1.5 13.8807 1.5 12.5V7.5C1.5 6.11929 2.61929 5 4 5H5ZM11 6H10H6H5H4C3.17157 6 2.5 6.67157 2.5 7.5V12.5C2.5 13.3284 3.17157 14 4 14H12C12.8284 14 13.5 13.3284 13.5 12.5V7.5C13.5 6.67157 12.8284 6 12 6H11Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Lock",
}
</script>
