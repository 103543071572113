<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.54802 5.09979L7.98344 2.5L6.41886 5.0998C6.13961 5.56382 5.68411 5.89476 5.1565 6.01695L2.20047 6.70157L4.18954 8.99296C4.54456 9.40194 4.71854 9.93741 4.67172 10.477L4.40937 13.4999L7.20326 12.3162C7.70193 12.105 8.26496 12.105 8.76362 12.3162L11.5575 13.4999L11.2952 10.477C11.2483 9.93741 11.4223 9.40194 11.7773 8.99296L13.7664 6.70157L10.8104 6.01695C10.2828 5.89476 9.82728 5.56382 9.54802 5.09979ZM8.84025 1.98436C8.45159 1.33854 7.51529 1.33855 7.12663 1.98437L5.56205 4.58416C5.42243 4.81617 5.19468 4.98164 4.93087 5.04274L1.97484 5.72736C1.24053 5.89743 0.951195 6.7879 1.4453 7.35711L3.43437 9.64849C3.61188 9.85298 3.69888 10.1207 3.67546 10.3905L3.41311 13.4134C3.34794 14.1643 4.10542 14.7147 4.79946 14.4206L7.59335 13.237C7.84269 13.1314 8.1242 13.1314 8.37353 13.237L11.1674 14.4206C11.8615 14.7147 12.6189 14.1643 12.5538 13.4134L12.2914 10.3905C12.268 10.1207 12.355 9.85298 12.5325 9.64849L14.5216 7.35711C15.0157 6.7879 14.7264 5.89743 13.992 5.72736L11.036 5.04274C10.7722 4.98164 10.5445 4.81617 10.4048 4.58416L8.84025 1.98436Z"
      fill="currentColor"
    />
  </svg>
</template>
