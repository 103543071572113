<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.5"
      y="6.5"
      width="15"
      height="7"
      rx="1.5"
      stroke="currentColor"
    />
    <path
      d="M10 2.5C10.7956 2.5 11.5587 2.81607 12.1213 3.37868C12.6839 3.94129 13 4.70435 13 5.5V14.5C13 15.2956 12.6839 16.0587 12.1213 16.6213C11.5587 17.1839 10.7956 17.5 10 17.5V17.5"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 14.5C13 15.2956 13.3161 16.0587 13.8787 16.6213C14.4413 17.1839 15.2044 17.5 16 17.5V17.5"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 2.5C15.2044 2.5 14.4413 2.81607 13.8787 3.37868C13.3161 3.94129 13 4.70435 13 5.5"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Rename",
}
</script>
