<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.35355 6.14645L7.5 10.2929V1.5C7.5 1.22386 7.72386 1 8 1C8.27614 1 8.5 1.22386 8.5 1.5V10.2929L12.6464 6.14645C12.8417 5.95118 13.1583 5.95118 13.3536 6.14645C13.5488 6.34171 13.5488 6.65829 13.3536 6.85355L8.35355 11.8536C8.15829 12.0488 7.84171 12.0488 7.64645 11.8536L2.64645 6.85355C2.45118 6.65829 2.45118 6.34171 2.64645 6.14645C2.84171 5.95118 3.15829 5.95118 3.35355 6.14645ZM2.5 15C2.22386 15 2 14.7761 2 14.5C2 14.2239 2.22386 14 2.5 14H13.5C13.7761 14 14 14.2239 14 14.5C14 14.7761 13.7761 15 13.5 15H2.5Z"
      fill="currentColor"
    />
  </svg>
</template>
