<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3798_46338)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.25 8C14.25 11.4518 11.4518 14.25 8 14.25C4.54822 14.25 1.75 11.4518 1.75 8C1.75 4.54822 4.54822 1.75 8 1.75C11.4518 1.75 14.25 4.54822 14.25 8ZM15.25 8C15.25 12.0041 12.0041 15.25 8 15.25C3.99594 15.25 0.75 12.0041 0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8ZM10.3499 9.64287C10.5472 9.83614 10.5504 10.1527 10.3571 10.3499C10.1639 10.5472 9.84729 10.5504 9.65006 10.3571L7.65004 8.39734L7.49999 8.2503L7.49999 8.04021L7.5 4C7.5 3.72386 7.72386 3.5 8 3.5C8.27614 3.5 8.5 3.72386 8.5 4L8.49999 7.83012L10.3499 9.64287Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3798_46338">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
